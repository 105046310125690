// Here you can add other styles
#cgmLogo{
    margin-top: 10px;
    margin-bottom: 10px;
}
.btn-primary {
    color: #fff;
    background-color: #024a7c;
    border-color: #024a7c;
}

.btn-primary:hover {
    color: #fff;
    background-color: #01396a;
    border-color: #01396a;
}
.btn-primary:active {
    color: #fff;
    background-color: #01396a;
    border-color: #01396a;
}
.btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #01396a;
    border-color: #01396a;
    box-shadow: 0 0 0 0.2rem;
}
.c-sidebar .c-sidebar-nav-link:hover, .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #fff;
    background: #024a7c;
}
a {
    text-decoration: none;
    background-color: transparent;
    color: #024a7c;
}
a:hover {
    text-decoration: none;
    background-color: transparent;
    color: #01396a;
}
.btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #01396a;
    border-color: #01396a;
}
.btn-link {
    font-weight: 400;
    color: #024a7c;
    text-decoration: none;
    }
    .bg-primary {
        background-color: #024a7c !important;
        }
.form-control:focus {
    color: #768192;
    background-color: #fff;
    border-color: #01396a;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #01396a;
    border-color: #01396a;
}

.dropdown-item.active, .dropdown-item:active {
    text-decoration: none;
    color: #fff;
    background-color: #01396a;
}